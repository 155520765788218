import * as UserEvents from 'src/utils/user/event';
import * as BatchUserEvents from 'src/utils/user/batch-event';
import { Nullable } from 'src/types/nullable.type';
import { UTMParameters } from 'src/utils/user/event.interface';

export const embedUserEventsFactory = (params: {
  aliasUUID: string;
  trackingUUID: string;
  sessionUUID: string;
  sessionReplayUUID?: string;
  email: Nullable<string>;
  utmParameters?: UTMParameters;
}) => ({
  createUserViewEvent: (contentID: string) => new UserEvents.View({ ...params, contentID }),
  createDocumentPageViewEvent: (contentID: string) => new UserEvents.DocumentPageView({ ...params, contentID }),
  createCalendarSubmissionEvent: (contentID: string) => new UserEvents.CalendarSubmission({ ...params, contentID }),
  createJourneyViewEvent: () => new UserEvents.JourneyView(params),
  createVideoPlayEvent: (contentID: string) => new UserEvents.VideoPlay({ ...params, contentID }),
  createEmailCaptureEvent: (email: string) => new UserEvents.EmailCapture({ ...params, email }),
  createVideoEndEvent: (contentID: string) => new UserEvents.VideoEnded({ ...params, contentID }),
  createCtaClickEvent: (contentID: string) => new UserEvents.CtaClick({ ...params, contentID }),
  createReactionEvent: (contentID: string) => new UserEvents.Reaction({ ...params, contentID }),
  createCommentEvent: (contentID: string, email: string) => new UserEvents.Comment({ ...params, contentID, email }),
  createExternalLinkVisitEvent: (contentID: string) => new UserEvents.LinkVisit({ ...params, contentID }),
  createChatbotInitializeEvent: () => new UserEvents.ChatbotInitialize({ ...params, }),
  createChatbotMessageExchangeEvent: () => new UserEvents.ChatbotMessageExchange({ ...params, }),
  createBatchUserEvent: () => new BatchUserEvents.BatchUserEvent({ ...params }),
});