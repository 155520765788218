import React from 'react';
import { NeueCheckbox } from './neue-checkbox';
import classNames from 'classnames';
import { RenderMode } from '../types';

export interface CheckboxOption {
  label: string;
  value: string;
}

export interface CheckboxOptionProps extends CheckboxOption {
  disabled?: boolean;
}

interface NeueCheckboxesProps {
  options: CheckboxOptionProps[];
  label?: string;
  value: string[];
  onChange?: (selectedValues: string[]) => void;
  className?: string;
  renderMode: RenderMode;
  labelClassName?: string;
  error?: string | false | null;
  orientation?: 'vertical' | 'horizontal';
}

export const NeueCheckboxes: React.FC<NeueCheckboxesProps> = ({
  options,
  label,
  value = [],
  onChange,
  className = '',
  renderMode,
  labelClassName,
  error = null,
  orientation = 'vertical',
}) => {
  const [checkeds, setCheckeds] = React.useState<string[]>(value);
  const isRenderModePlayer = renderMode !== 'editor';
  const valueAdapter = isRenderModePlayer ? checkeds : value;

  const handleCheckboxChange = (optionValue: string, checked: boolean) => {
    if (!onChange) {
      return;
    }

    let newSelectedValues: string[];

    if (checked) {
      newSelectedValues = [...valueAdapter, optionValue];
    } else {
      newSelectedValues = valueAdapter.filter((val) => val !== optionValue);
    }
    if (isRenderModePlayer) {
      setCheckeds(newSelectedValues);
    }

    onChange(newSelectedValues);
  };

  const containerClassName = classNames(className, {
    'flex flex-col space-y-3': orientation === 'vertical',
    'flex flex-row flex-wrap gap-4': orientation === 'horizontal',
  });

  return (
    <div className='flex flex-col gap-2'>
      {label && <span className={classNames('truncate pr-4', labelClassName)}>{label}</span>}
      <div className={containerClassName}>
        {options.map((option, index) => {
          return (
            <NeueCheckbox
              key={option.value}
              id={option.value}
              label={option.label}
              checked={valueAdapter.includes(option.value)}
              disabled={option.disabled}
              onChange={(_, checked) => handleCheckboxChange(option.value, checked)}
              error={index === 0 ? error : null}
            />
          );
        })}
      </div>
    </div>
  );
};
