import React from 'react';
import classNames from 'classnames';
import { Block, NativeFormBlockContent, RenderMode } from '../types';
import { BlockContentContainer } from '../components/block/content-container';
import { JourneyForm, JourneyFormik } from 'src/common/JourneyFormik';
import * as Yup from 'yup';
import { NativeFormDropdownOption, NativeFormElement, NativeFormTextInput } from './store';
import { FormikTextInput } from 'src/common/form/formik/text-input';
import { NeueListbox } from 'src/common/form/NeueListbox';
import { FormikProps } from 'formik';
import { AutoSubmitForm } from './auto-submit-form';
import { NeueCheckboxes } from '../components/neue-checkboxes';
import { NeueJourneyButton } from '../components/neue-button/journey';

type Props = {
  block: Block;
  selected: boolean;
  grabbing: boolean;
  editable: boolean;
  loading: boolean;
  contextMenuOpen: boolean;
  renderMode: RenderMode;
  measureElementRef: (element: HTMLElement | null) => void;
  onDelete: () => void;
};

export const NativeFormBlock = ({
  block,
  renderMode,
  onDelete,
  selected,
  grabbing,
  loading,
  contextMenuOpen,
  editable,
  measureElementRef,
}: Props) => {
  const blockContent = block.content as NativeFormBlockContent;

  const validationSchema = Yup.object({
    ...blockContent.fields.reduce((acc, field) => {
      return {
        ...acc,
        [field.id]: Yup.string().required('Required'),
      };
    }, {}),
  });

  const formikParams = {
    initialValues: blockContent.fields.reduce((acc, field) => {
      return {
        ...acc,
        [field.id]: (field as Exclude<NativeFormElement, NativeFormTextInput>)?.value || '',
      };
    }, {}),
    validationSchema,
    onSubmit: (values: Record<string, string>) => {
      console.log(values, ' < values');
    },
  };

  const showHeader = blockContent.config && (blockContent.config.title || blockContent.config.description);
  const showSubmitButton =
    blockContent.config && blockContent.config.submitMode === 'manual' && !!blockContent.config.submitButtonText;
  const submitButtonAlignment = blockContent.config?.submitButtonAlignment || 'left';

  return (
    <BlockContentContainer
      backgroundStyle='clear'
      loading={loading}
      selected={selected}
      grabbing={grabbing}
      contextMenuOpen={contextMenuOpen}
      renderMode={renderMode}
      className={classNames({
        'hover:bg-neue-canvas-fg-10': renderMode === 'editor' && !selected,
      })}
    >
      <div className='absolute flex items-center transition-all inset-0 rounded-2xl z-10 p-6'>
        <div className='relative w-full flex items-center justify-center space-x-6 h-full' ref={measureElementRef}>
          <JourneyFormik {...formikParams}>
            {({ setFieldValue }: FormikProps<Record<string, string>>) => {
              return (
                <JourneyForm className='w-[calc(100%-60px)] flex flex-col gap-4 shrink-0 justify-evenly h-full'>
                  {showHeader && (
                    <div className='flex flex-col gap-2'>
                      {blockContent.config.title && (
                        <h1 className='text-neue-canvas-desktop-title text-neue-canvas-fg'>
                          {blockContent.config.title}
                        </h1>
                      )}
                      {blockContent.config.description && (
                        <h2 className='text-neue-canvas-desktop-heading !font-medium text-neue-canvas-fg-50'>
                          {blockContent.config.description}
                        </h2>
                      )}
                    </div>
                  )}
                  <div className='flex flex-col gap-8 shrink-0'>
                    {blockContent.fields.map((field) => {
                      switch (field.type) {
                        case 'dropdown':
                          return (
                            <div className='flex flex-col gap-4 text-neue-canvas-fg shrink-0'>
                              <div className='flex flex-col gap-2'>
                                {field.label && <span className='text-neue-journey-medium-strong'>{field.label}</span>}
                                <NeueListbox
                                  defaultLabel='Select an option'
                                  options={field.options}
                                  renderKey={(option: NativeFormDropdownOption) => option.value}
                                  renderValue={(option: NativeFormDropdownOption) => option.label}
                                  labelClassName='text-neue-canvas-fg text-neue-journey-medium-strong'
                                  iconClassName='text-neue-journey-bg'
                                  buttonColorClassName='bg-neue-canvas-fg-5'
                                  onChange={(selected: NativeFormDropdownOption) => {
                                    setFieldValue(field.id, selected.value);
                                  }}
                                />
                              </div>
                              {field.description && <p className='text-neue-journey-medium'>{field.description}</p>}
                            </div>
                          );
                        case 'input':
                          return (
                            <div className='flex flex-col gap-4 text-neue-canvas-fg shrink-0'>
                              <FormikTextInput
                                isNeue
                                labelClassName='text-neue-canvas-fg text-neue-journey-medium-strong'
                                inputClasses={classNames('w-full !border-neue-canvas-fg-20 !bg-neue-canvas-fg-5', {
                                  'pointer-events-none': renderMode === 'editor',
                                })}
                                id={field.id}
                                name={field.id}
                                required
                                type='text'
                                label={field.label}
                              />
                              {field.description && <p className='text-neue-journey-medium'>{field.description}</p>}
                            </div>
                          );
                        case 'checkbox':
                          return (
                            <div className='flex flex-col gap-4 text-neue-canvas-fg shrink-0'>
                              <div className='flex flex-col gap-2'>
                                {field.label && <span className='text-neue-journey-medium-strong'>{field.label}</span>}
                                {field.description && <p className='text-neue-journey-medium'>{field.description}</p>}
                                <NeueCheckboxes
                                  renderMode={renderMode}
                                  options={field.options}
                                  orientation={field.orientation}
                                  labelClassName='text-neue-canvas-fg text-neue-journey-medium-strong'
                                  value={field.value}
                                  onChange={(values) => {
                                    setFieldValue(field.id, values);
                                  }}
                                />
                              </div>
                            </div>
                          );
                        default:
                          return null;
                      }
                    })}
                    {showSubmitButton ? (
                      <div
                        className={classNames('flex items-center', {
                          'justify-start': submitButtonAlignment === 'left',
                          'justify-center': submitButtonAlignment === 'center',
                          'justify-end': submitButtonAlignment === 'right',
                        })}
                      >
                        <NeueJourneyButton type='submit' className='h-10' disabled={blockContent.fields.length === 0}>
                          {blockContent.config.submitButtonText}
                        </NeueJourneyButton>
                      </div>
                    ) : (
                      <AutoSubmitForm />
                    )}
                  </div>
                </JourneyForm>
              );
            }}
          </JourneyFormik>
        </div>
      </div>
      <div
        className={classNames('absolute inset-0 rounded-2xl transition bg-transparent z-20', {
          hidden: !editable || selected,
          'pointer-events-none': selected,
        })}
      ></div>
    </BlockContentContainer>
  );
};
