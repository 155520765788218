import axios from 'axios';
import { AliasCustomFieldValueType } from 'src/common/interfaces/journey/alias.interface';
import { Nullable } from 'src/types/nullable.type';
import { UserEventInterface, UTMParameters } from './event.interface';

export class UserEvent {
  public static eventType: string = '';

  protected aliasUUID: string;
  protected contentID: Nullable<string>;
  protected email: Nullable<string>;
  protected trackingUUID: string;
  protected sessionUUID: string;
  protected sessionReplayUUID: Nullable<string>;
  protected utmParameters: Nullable<UTMParameters>;
  private eventUUID?: Nullable<string>;

  protected requestChain: Nullable<Promise<unknown>> = null;

  constructor(params: UserEventInterface) {
    this.aliasUUID = params.aliasUUID;
    this.contentID = params.contentID || null;
    this.email = params.email || null;
    this.trackingUUID = params.trackingUUID;
    this.sessionUUID = params.sessionUUID;
    this.sessionReplayUUID = params.sessionReplayUUID || null;
    this.utmParameters = params.utmParameters || null;
  }

  fire(payload: unknown = {}) {
    if (!this.eventUUID) {
      this.requestChain = axios
        .post<{ uuid?: string }>(
          `/api/journey-aliases/${this.aliasUUID}/events/${(this.constructor as typeof UserEvent).eventType}`,
          {
            data: payload,
            email: this.email,
            content_id: this.contentID,
            tracking_uuid: this.trackingUUID,
            session_uuid: this.sessionUUID,
            session_replay_uuid: this.sessionReplayUUID,
            utm_parameters: this.utmParameters
          }
        )
        .then((response) => {
          this.eventUUID = response.data.uuid || null;
        })
        .catch(console.error);
    } else {
      this.requestChain = (this.requestChain as Promise<unknown>).then(() =>
        axios.put(`/api/journey-events/${this.eventUUID}`, {
          data: payload,
        })
      );
    }
    return this.requestChain;
  }
}

export class VideoPlay extends UserEvent {
  static eventType = 'video_play';
}

export class VideoPause extends UserEvent {
  static eventType = 'video_pause';
}

export class LinkVisit extends UserEvent {
  static eventType = 'link_visit';
}

export class VideoEnded extends UserEvent {
  static eventType = 'video_ended';
}

export class CtaClick extends UserEvent {
  static eventType = 'cta_click';
}

export class VideoView extends UserEvent {
  static eventType = 'video_view';
}

export class Comment extends UserEvent {
  static eventType = 'comment';

  fire(comment: string) {
    return super.fire({ comment });
  }
}

export class EmailCapture extends UserEvent {
  static eventType = 'email_capture';

  fire(customFieldValues?: AliasCustomFieldValueType[]) {
    return super.fire({ email: this.email, custom_field_values: customFieldValues });
  }
}

export class Reaction extends UserEvent {
  static eventType = 'reaction';

  fire(emoji: string) {
    return super.fire({ emoji });
  }
}

export class View extends UserEvent {
  static eventType = 'view';

  fire(duration = 0) {
    return super.fire({ duration });
  }
}

export class JourneyView extends UserEvent {
  static eventType = 'journey_view';

  fire(params: any) {
    return super.fire({ ...params });
  }
}

export class DocumentPageView extends UserEvent {
  static eventType = 'document_page_view';

  fire(duration = 0, page_index = 0) {
    return super.fire({ duration, page_index });
  }
}

export class CalendarSubmission extends UserEvent {
  static eventType = 'calendar_submission';

  fire(params: any) {
    return super.fire({ ...params });
  }
}

export class ChatbotInitialize extends UserEvent {
  static eventType = 'chatbot_initialize';

  fire(params: any) {
    return super.fire({ ...params });
  }
}

export class ChatbotMessageExchange extends UserEvent {
  static eventType = 'chatbot_message_exchange';

  fire(params: any) {
    return super.fire({ ...params });
  }
}
