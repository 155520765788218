import { BlockContentSizeMap, Section } from '../types';

const getBlocksThatNeedMeasuredHeight = (sections: Section[]) => {
  return sections
    .flatMap((s) => s.blocks)
    .filter((b) => {
      // We only need measurement for calculated height blocks
      if (b.layout.spec.type !== 'calculated-height') {
        return false;
      }

      // We do have some exceptions for now (need to figure out a better way to handle this)

      // Let's be explicit about each block type so that we get build errors when we add a new unhandled block type
      const { type } = b.content;
      if (
        type === 'text' ||
        type === 'attachment' ||
        type === 'mutual-action-plan' ||
        type === 'pdf' ||
        type === 'contents-list' ||
        type === 'table' ||
        type === 'signup'
      ) {
        return true;
      } else if (
        type === 'image' ||
        type === 'video' ||
        type === 'placeholder' ||
        type === 'ms-office' ||
        type === 'native-e-signature'
      ) {
        // We don't expect to have any of these blocks with calculated height.
        // This might cause issues right now, but we'd rather not block page load
        return false;
      } else if (type === 'logo') {
        // Logo blocks don't report a non-zero height today for some reason right now
        return false;
      } else if (type === 'talk-to-journey') {
        // Talk to journey block is a calculated height block
        // that behaves like a static height block. But there are instances in the database
        // already exposed to customers, so can't change it without a migration on json structures.
        // We'll just have to live with this for now.
        return false;
      } else if (type === 'cta-button') {
        return false;
      } else if (type === 'link') {
        // Inline embeds don't need height
        return b.content.displayOption !== 'inline';
      } else if (type === 'native-form') {
        return false;
      } else {
        const _exhaustiveCheck: never = type;
        throw new Error(`Unhandled block type ${type}`);
      }
    });
};

export const hasAnyBlockWithoutHeight = (sections: Section[], blockContentSizes: BlockContentSizeMap) => {
  const calcHeightBlocks = getBlocksThatNeedMeasuredHeight(sections);
  const blockWithoutHeight = calcHeightBlocks.find((b) => {
    const height = blockContentSizes[b.id]?.height || 0;
    const overrideHeight = b.layout.spec.height || 0;
    if (height <= 0 && overrideHeight <= 0) {
      console.warn('Block without height', b, 'reported height', blockContentSizes[b.id]?.height);
      return true;
    }
    return false;
  });
  return !!blockWithoutHeight;
};
