import classNames from 'classnames';
import React, { ReactNode, useMemo, useRef, useCallback, forwardRef } from 'react';
import { Nullable } from 'src/types/nullable.type';

export interface TextInputPropsInterface
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  id: string;
  label?: Nullable<string>;
  required?: boolean;
  disabled?: boolean;
  branded?: boolean;
  isNeue?: boolean;
  hideOptional?: boolean;
  maxLength?: number;
  hideCharacterCount?: boolean;
  inputProps?: any;
  containerClasses?: string;
  inputClasses?: string;
  errorClasses?: string;
  style?: React.CSSProperties;
  helperText?: string;
  inlineAddon?: ReactNode;
  trailingAddon?: ReactNode;
  labelClassName?: string;
  onChange?: any;
  onKeyDown?: any;
  onBlur?: any;
  autoFocus?: boolean;
  error?: boolean;
  errorText?: string;
  showBorder?: boolean;
  selectOnFocus?: boolean;
  showWarningOnExceedingMaxLength?: boolean;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputPropsInterface>(
  (
    {
      label,
      id,
      required,
      disabled,
      isNeue = false,
      inputProps,
      hideOptional,
      maxLength,
      hideCharacterCount = false,
      containerClasses,
      inputClasses,
      helperText,
      inlineAddon,
      trailingAddon,
      labelClassName = 'text-bedrock-p-strong text-bedrock-black',
      onChange,
      onKeyDown,
      onBlur,
      onFocus,
      autoComplete,
      autoFocus = false,
      error,
      errorText = null,
      errorClasses,
      showBorder = true,
      selectOnFocus = false,
      showWarningOnExceedingMaxLength = false,
      ...rest
    },
    ref
  ) => {
    const hasError = error && errorText && errorText.trim();
    const inputRef = useRef(null);

    const inputClassNames = useMemo(() => {
      if (isNeue) {
        return classNames('neue-journey-text-input pr-12', inputClasses);
      }
      if (!showBorder) {
        return classNames(
          'block w-full transition-all text-bedrock-h2 border-transparent p-0 text-bedrock-serif-h2 placeholder:text-bedrock-dark-gray text-bedrock-black focus-visible:border-transparent focus:ring-0',
          inputClasses
        );
      }

      return classNames(
        'block w-full transition-all',
        inputClasses,
        'px-[14px] py-[10px] text-bedrock-black text-bedrock-mobile-input placeholder:text-bedrock-dark-gray placeholder:align-middle placeholder:font-normal rounded-lg disabled:opacity-40',
        'border-2 border-bedrock-gray-medium',
        {
          // 'border-transparent p-0': !showBorder,
          'focus:ring-3 focus:ring-bedrock-brand-primary/transparent focus:border-bedrock-brand-secondary focus-visible:border-bedrock-brand-secondary active:border-bedrock-brand-secondary hover:border-bedrock-brand-secondary':
            !hasError,
          'focus:ring-0 border-bedrock-red focus:border-bedrock-red focus-visible:border-bedrock-red': hasError,
          // 'focus:ring-transparent focus:border-transparent': !showBorderOnFocus,
        }
      );
    }, [showBorder, hasError, inputClasses, isNeue]);

    const labelClasses = classNames('block', labelClassName);

    const helperTextClasses = 'mt-2 text-bedrock-p-small text-bedrock-black';
    const errorTextClasses = classNames('mt-2 text-bedrock-p-small text-bedrock-red', errorClasses);

    const onClick = useCallback(() => {
      const r = ref || (inputRef as any);
      if (selectOnFocus && r) {
        r.current?.select();
      }
    }, [ref, inputRef, selectOnFocus]);

    const inputPropsExtended = {
      ...inputProps,
      autoFocus,
      disabled,
      onChange,
      onClick,
      onKeyDown,
      onBlur,
      onFocus,
      ...(!showWarningOnExceedingMaxLength && { maxLength }),
    };

    const inputLength = inputProps.value?.length || 0;

    return (
      <div className={containerClasses}>
        {label ? (
          <div className='flex justify-between mb-sm'>
            <label htmlFor={id} className={labelClasses}>
              {label}
            </label>
          </div>
        ) : null}
        <div className='relative flex group'>
          {inlineAddon}
          <input
            ref={ref || inputRef}
            autoComplete={autoComplete}
            id={id}
            {...inputPropsExtended}
            className={classNames(inputClassNames, '!outline-transparent')}
          />
          {trailingAddon && <div className='absolute inset-y-0 right-0 px-4 flex items-center'>{trailingAddon}</div>}
        </div>
        {!hideCharacterCount && maxLength && (showWarningOnExceedingMaxLength ? inputLength > maxLength : true) && (
          <div
            className={classNames('text-bedrock-p-small mt-2', {
              'text-bedrock-dark-gray': inputLength <= maxLength,
              'text-bedrock-red': inputLength > maxLength,
            })}
          >
            {inputLength} / {maxLength}
          </div>
        )}
        {helperText && <div className={helperTextClasses}>{helperText}</div>}
        {hasError && <div className={errorTextClasses}>{errorText}</div>}
      </div>
    );
  }
);
