import React, { useRef } from 'react';
import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import { useUpdateEffect } from 'react-use';

export const AutoSubmitForm = ({ delay = 500 }: { delay?: number }) => {
  const { values, submitForm, isValid } = useFormikContext();
  const debouncedSubmit = useRef(
    debounce(() => {
      if (isValid) {
        submitForm();
      }
    }, delay)
  ).current;

  useUpdateEffect(() => {
    debouncedSubmit();

    return () => {
      debouncedSubmit.cancel();
    };
  }, [values]);

  return null;
};
