import React, { ComponentProps } from 'react';
import {
  FilePlusStrongIcon,
  FormStrongIcon,
  ImageBoldNeueIcon,
  LogoBlockStrongIcon,
  NeueContentsListStrongIcon,
  SquiggleStrongIcon,
  UploadStrongIcon,
  VideoCameraStrongIcon,
} from 'src/monet/icons';
import { BlockPlaceholderType, LinkIntegrationType, PlaceholderFileType } from '../types';
import { Nullable } from 'src/types/nullable.type';
import LinkBoldNeueIcon from 'src/monet/icons/LinkBoldNeueIcon';
import BookBoldNeueIcon from 'src/monet/icons/BookBoldNeueIcon';
import { IntegrationItems } from '../create-toolbar/integration-items';

export function PlaceHolderIcon({
  blockPlaceholderType,
  fileType,
  className,
  icon,
  integration,
}: {
  blockPlaceholderType: BlockPlaceholderType;
  className?: string;
  fileType?: PlaceholderFileType;
  icon?: Nullable<(props: ComponentProps<'svg'>) => JSX.Element>;
  integration?: LinkIntegrationType;
}) {
  let IconComponent = null;
  if (icon) {
    IconComponent = icon;
  } else if (blockPlaceholderType === 'add-image') {
    IconComponent = ImageBoldNeueIcon;
  } else if (blockPlaceholderType === 'add-link') {
    IconComponent = LinkBoldNeueIcon;
  } else if (blockPlaceholderType === 'add-media') {
    IconComponent = BookBoldNeueIcon;
  } else if (blockPlaceholderType === 'add-file') {
    if (fileType) {
      IconComponent = FilePlusStrongIcon;
    } else {
      IconComponent = UploadStrongIcon;
    }
  } else if (blockPlaceholderType === 'native-form') {
    IconComponent = FormStrongIcon;
  } else if (blockPlaceholderType === 'add-logo') {
    IconComponent = LogoBlockStrongIcon;
  } else if (blockPlaceholderType === 'add-contents-list') {
    IconComponent = NeueContentsListStrongIcon;
  } else if (blockPlaceholderType === 'add-recording') {
    IconComponent = VideoCameraStrongIcon;
  } else if (blockPlaceholderType === 'add-esignature-link') {
    IconComponent = SquiggleStrongIcon;
  } else if (blockPlaceholderType === 'add-integration' && integration) {
    const integrationIcon = IntegrationItems.find((item) => item.type === integration.type)?.Icon;
    if (integrationIcon) {
      IconComponent = integrationIcon;
    }
  } else if (blockPlaceholderType === 'add-native-e-signature') {
    IconComponent = SquiggleStrongIcon;
  }

  return IconComponent ? <IconComponent className={className} /> : null;
}

export function toPlaceHolderLabel(
  blockPlaceholderType: BlockPlaceholderType,
  integration?: LinkIntegrationType,
  fileType?: PlaceholderFileType
) {
  if (blockPlaceholderType === 'add-image') {
    return 'Add image';
  } else if (blockPlaceholderType === 'add-link') {
    return 'Add link';
  } else if (blockPlaceholderType === 'add-media') {
    return 'Add from Library';
  } else if (blockPlaceholderType === 'add-file') {
    if (fileType) {
      if (fileType.type === 'video') {
        return 'Add video';
      } else if (fileType.type === 'document') {
        return 'Add document';
      }
    }
    return 'Add file';
  } else if (blockPlaceholderType === 'add-logo') {
    return 'Add logos';
  } else if (blockPlaceholderType === 'add-contents-list') {
    return 'Add contents list';
  } else if (blockPlaceholderType === 'add-recording') {
    return 'Add recording';
  } else if (blockPlaceholderType === 'add-esignature-link') {
    return 'Add e-Signature';
  } else if (blockPlaceholderType === 'add-integration' && integration) {
    return `Add ${integration.label}`;
  } else if (blockPlaceholderType === 'add-native-e-signature') {
    return 'Configure e-Signature';
  }
}
