import React, { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Block, LayoutMode, RenderElement, SectionLayoutInfo, Size } from '../types';
import { functionNoop } from 'src/utils/function/noop';
import { TargetElement } from '../insights/use-visible-duration';
import { Nullable } from 'src/types/nullable.type';
import { useSprings, animated, to, SpringValue } from '@react-spring/web';
import { useRefCallback } from 'src/utils/react/ref-callback.hook';
import { createPropsEqualsChecker } from '../utils/create-props-equals-checker';
import { MemoizedNeuePlayerBlockView } from './block-view';
import useQueryParams from 'src/common/hooks/useQueryParams';
import { useScrollToSectionById } from './scroll-to-section-by-id.hook';
import { useUpdateEffect } from 'react-use';

type Props = {
  journeyUUID: Nullable<string>;
  width: number;
  height: number;
  playerContentElement: HTMLElement;
  innerAreaHeight: number;
  renderElements: RenderElement[];
  sectionLayoutInfos: SectionLayoutInfo[];
  layoutMode: LayoutMode;
  layoutComplete: boolean;
  observeElements?: (elements: TargetElement[]) => void;
  disconnectObservers?: () => void;
  scrollToSectionId: Nullable<string>;
  onBlockSize: (blockId: Block['id'], size: Nullable<Size>) => void;
};

export const NeuePlayerBlocksView = memo(
  ({
    journeyUUID,
    width,
    height,
    playerContentElement,
    innerAreaHeight,
    renderElements,
    sectionLayoutInfos,
    observeElements = functionNoop,
    disconnectObservers = functionNoop,
    layoutMode,
    layoutComplete,
    onBlockSize,
    scrollToSectionId,
  }: Props) => {
    // div refs is a mapping of block id to div ref
    const divRefs = useRef<Record<string, HTMLDivElement>>({});
    const query = useQueryParams();
    const pdfPreview = !!query.get('pdf_preview');
    console.log('PDF Preview', pdfPreview);
    const [singleSection, setSingleSection] = useState<Nullable<number>>(null);
    useEffect(() => {
      if (pdfPreview) {
        setSingleSection(0);
      }
    }, [pdfPreview]);

    const scrollToSection = useScrollToSectionById();

    useUpdateEffect(() => {
      if (scrollToSectionId) {
        scrollToSection(scrollToSectionId);
      }
    }, [scrollToSectionId]);

    useLayoutEffect(() => {
      if (renderElements.length) {
        const divs = Object.entries(divRefs.current).map(([key, value]) => Object.assign(value, { id: key }));
        observeElements(divs);
      }
      return () => {
        disconnectObservers();
      };
    }, [renderElements.length]);

    const [sectionSprings, sectionSpringsApi] = useSprings(sectionLayoutInfos.length, (index) => ({
      from: { visibilityFraction: 0 },
      to: { visibilityFraction: 1 },
    }));

    const handleScroll = useRefCallback(() => {
      const scrollTop = playerContentElement.scrollTop;
      const scrollBottom = scrollTop + playerContentElement.clientHeight;
      const fadeStartYTop = scrollBottom - 0.1 * innerAreaHeight;
      const fadeCompleteYTop = scrollBottom - 0.25 * innerAreaHeight;
      const fadeStartYBottom = scrollTop + 0.1 * innerAreaHeight;
      const fadeCompleteYBottom = scrollTop + 0.25 * innerAreaHeight;
      const sectionOpacities = sectionLayoutInfos.map(({ rect }, index) => {
        const sectionTop = rect.y;
        const sectionBottom = rect.y + rect.height;
        if (sectionTop > fadeStartYTop) {
          return 0;
        }
        if (sectionTop <= fadeStartYTop && sectionTop > fadeCompleteYTop) {
          return (fadeStartYTop - sectionTop) / (fadeStartYTop - fadeCompleteYTop);
        }
        if (sectionBottom < fadeStartYBottom) {
          return 0;
        }
        if (sectionBottom >= fadeStartYBottom && sectionBottom < fadeCompleteYBottom) {
          return (sectionBottom - fadeStartYBottom) / (fadeCompleteYBottom - fadeStartYBottom);
        }

        return 1;
      });
      sectionSpringsApi.start((index) => ({
        visibilityFraction: sectionOpacities[index],
        immediate: pdfPreview,
      }));
    }, [sectionLayoutInfos, innerAreaHeight]);

    React.useEffect(() => {
      playerContentElement.addEventListener('scroll', handleScroll, { passive: true });
      return () => {
        playerContentElement.removeEventListener('scroll', handleScroll);
      };
    }, [playerContentElement, handleScroll]);

    React.useEffect(() => {
      handleScroll();
    }, [sectionLayoutInfos]);

    let totalHeight = height;

    if (singleSection !== null && sectionLayoutInfos.length !== 0) {
      if (singleSection === 0) {
        totalHeight =
          2 * (sectionLayoutInfos[singleSection].rect.height / 2 + sectionLayoutInfos[singleSection].rect.y);
      } else if (singleSection !== sectionLayoutInfos.length - 1) {
        totalHeight = sectionLayoutInfos[singleSection + 1].rect.y - sectionLayoutInfos[singleSection].rect.y;
      } else {
        totalHeight = sectionLayoutInfos[sectionLayoutInfos.length - 1].rect.height + 240;
      }
    }

    return (
      <div className='relative pdf-root-to-get-measures' style={{ width, height: totalHeight }}>
        {pdfPreview && (
          <button
            id='pdf-next-section'
            className='absolute top-0 left-0 z-[100]'
            onClick={() => {
              setSingleSection((singleSection || 0) + 1);
            }}
          >
            Next
          </button>
        )}
        {renderElements.map((re) => {
          if (re.type === 'block') {
            const { block, sectionIndex } = re;
            let visibilityFraction = sectionSprings[sectionIndex].visibilityFraction;

            if (pdfPreview) {
              let heightSoFar;
              if (sectionIndex === 0) {
                heightSoFar = 0;
              } else {
                heightSoFar = sectionLayoutInfos[sectionIndex].rect.y - 120;
              }
              re = { ...re, rect: { ...re.rect, y: re.rect.y - heightSoFar } };
              visibilityFraction = new SpringValue(1);
            }
            return (
              <MemoizedNeuePlayerBlockView
                key={block.id}
                renderElement={re}
                onBlockSize={onBlockSize}
                layoutMode={layoutMode}
                layoutComplete={layoutComplete}
                displayNone={pdfPreview && sectionIndex !== singleSection}
                opacity={visibilityFraction}
                ref={(ref) => (divRefs.current[block.id] = ref as HTMLDivElement)}
                journeyUUID={journeyUUID}
              />
            );
          } else if (re.type === 'divider') {
            const { rect, nextSectionIndex, previousSectionIndex } = re;

            if (nextSectionIndex === null || pdfPreview) {
              return null;
            }
            if (layoutMode === 'web') {
              return (
                <animated.div
                  className='absolute left-0 w-full h-0'
                  style={{
                    top: `${rect.y}px`,
                    opacity: to(
                      [
                        previousSectionIndex === null ? 0 : sectionSprings[previousSectionIndex].visibilityFraction,
                        sectionSprings[nextSectionIndex].visibilityFraction,
                      ],
                      (previousOpacity, nextOpacity) => {
                        return Math.min(previousOpacity, nextOpacity);
                      }
                    ),
                  }}
                >
                  <div className='w-full h-full flex items-center justify-center gap-4 opacity-20'>
                    <div className='text-neue-journey-medium-strong text-neue-journey-bg'>{nextSectionIndex + 1}</div>
                    <div className='grow h-[3px] scale-y-50 rounded-full bg-neue-journey-bg' />
                  </div>
                </animated.div>
              );
            } else {
              return (
                <animated.div
                  className='absolute -left-4 -right-4 h-0 flex items-center justify-center gap-4'
                  style={{
                    top: `${rect.y}px`,
                    opacity: sectionSprings[nextSectionIndex].visibilityFraction,
                  }}
                >
                  <div className='absolute inset-0 opacity-10 h-[3px] scale-y-50 rounded-full bg-neue-journey-bg'></div>
                </animated.div>
              );
            }
          }
        })}
      </div>
    );
  }
);

export const MemoizedNeuePlayerBlocksView = React.memo(
  NeuePlayerBlocksView,
  createPropsEqualsChecker([], 'NeuePlayerBlocksView')
);
